// i18next-extract-mark-ns-start products-fearce-nvh

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							FEARCE includes an advanced noise, vibration and
							harshness (NVH) module for vibration and sound
							prediction. The FEARCE NVH module carries out vibration
							analysis on models by performing a forced response after
							a model analysis has been run. A sinusoidal forcing
							function is applied to the mode shapes as a Fourier
							loading on individual nodes in the frequency domain.
							This loading is then solved to calculate the resulting
							contribution of each mode. Finally, the model
							contributions are combined to give complex vibration
							levels for each forcing frequency. Output can be in
							either the time or frequency domain. Calculated values
							are nodal displacement, velocity and acceleration
							spectra.
						</p>
						<p>
							As a part of the vibration tools, FEARCE can carry out
							radiated noise analysis. Methods include the Rayleigh
							integral as well as the boundary element method (BEM).
							Noise outputs include dBA weighted, narrow band or 1/3
							octaves, noise-intensity contours, audible-noise
							synthesis (*.wav), total sound power and Campbell
							diagrams. Vibration results can also be exported to
							SYSNOISE® for further post-processing.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Direct and indirect vibration solutions</li>
							<li>Time domain and frequency domain calculations</li>
							<li>Rayleigh and BEM solution methods</li>
							<li>Automatic creation of BEM meshes</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-nvh", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
}
`;
